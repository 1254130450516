import api from "../../api";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Col, Form, InputNumber, Row} from "antd";

export default function MultiBed() {

    const [bed, setBed] = useState([]);
    const [plantings, setPlantings] = useState([]);
    const {garden_id, bed_id} = useParams();
    const [lefts, setLefts] = useState({})

    function calculateLefts() {
        let nextLeft = 0
        let newLefts = {}
        for(let i = 0; i < plantings.length; i++) {
            const planting = plantings[i]
            newLefts[planting.id] = nextLeft; // Store current left
            nextLeft += planting.rows * planting.crop.row_distance; // Accumulate properly
        }

        setLefts(newLefts)
    }

    useEffect(() => {
        api.bed(garden_id, bed_id).then((bed) => {
            setBed(bed)
            setPlantings(bed.plantings)
        })
    }, []);

    function updateLeft(value, planting) {
        if (!value) {
            return
        }
        const newPlantings = plantings.map(p => {
            if (p.id === planting.id) {
                p.rows = value
            }
            return p
        })
        setPlantings(newPlantings)
    }

    useEffect(() => {
        calculateLefts()
    }, [plantings]);

    useEffect(() => {
        if (bed && bed.plantings) {
            setPlantings(bed.plantings)
        }
        calculateLefts()
    }, [bed])

    return (
        <div>
            <h1>{bed.name}</h1>
            <Form layout={"horizontal"}>
                {plantings.map(planting => (
                    <Row key={`planting${planting.id}`}>
                        <Col span={4}>
                            {planting.crop.name}
                        </Col>
                        <Col span={4}>
                            <Form.Item>
                                <InputNumber onKeyUp={(event) => updateLeft(Number(event.target.value), planting)}
                                             onChange={(value) => updateLeft(value, planting)}
                                             defaultValue={planting.rows}/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item>
                                <InputNumber defaultValue={planting.plants_per_row}/>
                            </Form.Item>
                        </Col>
                        <Col>
                            {lefts[planting.id]}
                        </Col>
                    </Row>
                ))}
            </Form>

            <h2>Bed visualisatie</h2>

            <div style={{width: bed.length, height: bed.width, backgroundColor: 'green', border: 'solid 1px blue', position: 'relative'}}>
                {plantings.map(planting => (
                    <div key={`planting${planting.id}`} style={{
                        position: 'absolute',
                        top: 0,
                        left: lefts[planting.id],
                        height: bed.width,
                        width: (planting.rows * planting.crop.row_distance),
                        backgroundImage: `url(${planting.crop.image})`,
                        backgroundSize: 'contain',
                    }}>
                        {planting.crop.name}
                    </div>
                ))}
            </div>
        </div>
    )
}