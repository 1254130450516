import React, {createContext, useContext, useState} from "react";
import api from "./api";

const TranslationContext = createContext();

export const TranslationProvider = ({children}) => {

    const localLanguage = localStorage.getItem('language');

    const [translations, setTranslations] = useState(null);
    const [language, setLanguage] = useState(localLanguage ? localLanguage : 'nl');

    function switchLanguage(lang) {

        if (lang === language) {
            return
        }
        setLanguage(lang)
        console.log('switching language to', lang)
        localStorage.setItem('language', lang);
        document.location = document.location
    }

    if(!translations){
        api.getAllTranslations().then((translations) => {
            setTranslations(translations[language])
        })
    }


    return (
        <TranslationContext.Provider value={{translations, setTranslations, language, switchLanguage}}>
            {children}
        </TranslationContext.Provider>
    );
};

export const useTranslationState = () => {
    const context = useContext(TranslationContext);
    if (!context) throw new Error("useTranslationState must be used within a TranslationProvider");
    return context;
};

export const useTranslations = () => {
    const {translations, setTranslations, language, switchLanguage} = useTranslationState();

    const translate = (key) => {
        if (!translations) {
            return key;
        }
        if (translations[key]) {
            return translations[key];
        }
        setTranslations({...translations, [key]: key});

        api.addMissingTranslation(key)
        return key;
    };

    return {
        translate,
        language,
        switchLanguage
    };
};