import React from 'react'
import {Button} from "antd";
import api from "../../api";
import {useTranslations} from "../../lang";

export default function PaymentRetry() {

    const {translate} = useTranslations()

    function retryPayment(){
        api.retryPayment().then(({url}) => {
            document.location = url
        })

    }

    return (
        <div>
            <h1>{translate('De betaling is mislukt :-( !')}</h1>
            <Button onClick={() => retryPayment()} size={"large"} type={"primary"}>{translate('Klik hier om het opnieuw te proberen')}</Button>
        </div>
    )
}