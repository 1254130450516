import React, {useEffect, useState} from 'react'
import {Alert, Button, Col, Form, Input, Row, Spin, message} from "antd";
import api from "../../api";
import {useForm} from "antd/es/form/Form";
import {useParams} from "react-router-dom";
import {ArrowRightOutlined} from "@ant-design/icons";
import {useTranslations} from "../../lang";

const AcceptInvite = () => {

    const {id, token} = useParams()
    const [invite, setInvite] = useState(null)
    const [form] = useForm()
    const {translate} = useTranslations()

    useEffect(() => {
        api.getInvite(id, token).then(setInvite)
    }, []);

    useEffect(() => {
        if (!invite) {
            return
        }
        form.setFieldValue('email', invite?.email)
    }, [invite]);

    const onFinish = (values) => {
        api.acceptInvite(id, token, values.password).then((accepted) => {
            document.location = '/login?invite_accepted=1'
        })
    }

    return (
        <Row justify="center">
            <Col md={6} xs={24}>
                <h1>{translate('Uitnodiging accepteren') }</h1>
                {(!invite) && (
                    <Alert description={(
                        <><Spin className={"mar-r-1"}/> {translate('Moment, we laden je uitnodiging...')}</>
                    )} />
                )}
                {(invite) && (
                    <>
                        <h2>Hallo {invite.name} 👋!</h2>
                        <h3 className={"mar-b-1"}>
                            {translate('Je bent uitgenodigd om deel te nemen aan het team van')} <strong>{invite.company}</strong>
                        </h3>
                        <Form form={form} layout={"vertical"} onFinish={onFinish}>
                            <Form.Item
                                label={"Emailadres"}
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: translate('Vul een emailadres in'),
                                    },
                                    {
                                        type: 'email',
                                        message: translate('Vul een geldig emailadres in'),
                                    },
                                ]}
                            >
                                <Input disabled={true} placeholder="E-mail address"/>
                            </Form.Item>
                            <h2>Voor je account</h2>
                            <Form.Item
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: translate('Voer een wachtwoord in!'),
                                    },
                                ]}
                            >
                                <Input.Password placeholder="Wachtwoord"/>
                            </Form.Item>
                            <Form.Item
                                name="password_confirm"
                                rules={[
                                    {
                                        required: true,
                                        message: translate('Voer een wachtwoord in!'),
                                    },
                                    ({getFieldValue}) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error(translate('De wachtwoorden die je hebt ingevuld komen niet overeen!')));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password placeholder="Herhaal wachtwoord"/>
                            </Form.Item>
                            <div>
                                <Button type="primary" size={"large"} htmlType="submit" className="login-form-button">
                                    {translate('Uitnodiging accepteren')} <ArrowRightOutlined/>
                                </Button>
                            </div>
                        </Form>
                    </>
                )}
            </Col>
        </Row>

    )
}

export default AcceptInvite