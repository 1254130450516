import React, {useEffect} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import api from "../../api";
import {Spin} from "antd";

export default function Index() {

    const navigate = useNavigate();
    const mobile_app = new URLSearchParams(window.location.search).get('mobile_app')
    useEffect(() => {
        if (api.isLoggedIn()) {
            navigate("/gardens");
        } else {
            console.log()
            if (mobile_app) {
                navigate("/login");
                return
            }
            document.location = 'https://www.oogst.app/';
        }
    }, []);

    return (
        <div>
            <Spin></Spin>
        </div>
    )
}