import React from "react";
import {Button, Col, Form, Input, InputNumber, message, Modal, Radio, Row, Select} from "antd";
import {useForm} from "antd/es/form/Form";
import api from "../../api";
import {useTranslations} from "../../lang";

const {confirm} = Modal;

export default function BedModal({garden, open, onCancel, bed}) {

    const [form] = useForm();
    const {translate} = useTranslations()

    if(bed) {
        form.setFieldValue('name', bed.name)
        form.setFieldValue('width', bed.width)
        form.setFieldValue('length', bed.length)
        // form.setFieldValue('mode', bed.mode) /* TODO MULTI BED */
    }

    function deleteBed(){
        confirm({
            title: translate('Weet je zeker dat je dit bed wilt verwijderen?'),
            content: translate('Dit kan niet ongedaan worden gemaakt!'),
            onOk() {
                api.removeBed(garden.id, bed.id).then(() => {
                    message.success(translate('Bed verwijderd! Sluit dit venster om terug te gaan naar de planner.'))
                    onCancel()
                })
            },
            onCancel() {
            },
        });
    }

    function save() {
        if(bed) {
            api.updateBed(garden.id, bed.id, {
                name: form.getFieldValue('name'),
                width: form.getFieldValue('width'),
                length: form.getFieldValue('length'),
                mode: "single", // form.getFieldValue('mode') /* TODO MULTI BED */
            }).then(() => {
                message.success(translate('Bed bijgewerkt! Let op! Als je de afmetingen hebt gewijzigd, kan dit gevolgen hebben voor de planten die al in het bed staan.'))
                onCancel()
            })
            return
        }
        form.validateFields().then(values => {
            api.addBed(garden.id, {
                name: values.name,
                width: values.width,
                length: values.length,
                mode: "single" // values.mode, /* TODO MULTI BED */
            }).then(() => {
                message.success(translate('Bed toegevoegd! Sluit dit venster om terug te gaan naar de planner of voeg direct nog een bed toe!'))
                // onCancel()
            })
        })
    }

    return (
        <Modal
            open={open}
            onCancel={onCancel}
            footer={(
                <Row>
                    <Col span={12} className={"text-left"}>
                        {(bed && bed.id) && <Button danger onClick={() => deleteBed()}>{translate('Bed verwijderen')}</Button>}
                    </Col>
                    <Col span={12} className={"text-right"}>
                        <Button type={"primary"} onClick={save}>
                            {bed ? translate('Bed aanpassen') : translate('Bed toevoegen')}
                        </Button>
                    </Col>
                </Row>
            )}
        >
            <h1>
                {bed ? translate('Bewerk bed') : translate('Voeg een bed toe')}
            </h1>
            <Form form={form}
                labelCol={{span: 12}}>
                <Form.Item name="name" label={translate('Naam van het bed')} labelAlign="left">
                    <Input placeholder={translate('Voer een naam in')} style={{width: "100%"}}/>
                </Form.Item>
                <Form.Item name="width" label={translate('Breedte van het bed (cm)')} labelAlign="left">
                    <InputNumber placeholder={translate('Voer een breedte in')} style={{width: "100%"}}/>
                </Form.Item>
                <Form.Item name="length" label={translate('Lengte van het bed (cm)')} labelAlign="left">
                    <InputNumber placeholder={translate('Voer een lengte in')} style={{width: "100%"}}/>
                </Form.Item>
                {/* TODO MULTI BED */}
                {/*<Form.Item name="mode" label="Type bed" labelAlign="left">*/}
                {/*    <Select defaultValue={"single"} options={[*/}
                {/*        {label: 'één gewas tegelijk', value: 'single'},*/}
                {/*        {label: 'meerdere gewassen tegelijkertijd per bed', value: 'multi'},*/}
                {/*    ]}></Select>*/}
                {/*</Form.Item>*/}
            </Form>
        </Modal>
    )
}