import React, {useEffect, useState} from 'react';
import {Button, Col, Dropdown, Layout, Menu, Row, theme} from 'antd';
import {useNavigate, useRoutes} from "react-router-dom";
import {isMobile} from "react-device-detect";
import routes from "../../routes";
import './style.css';
import {
    CloseOutlined,
    HomeFilled, IdcardFilled,
    LogoutOutlined, MenuOutlined, QuestionCircleFilled, QuestionCircleOutlined, SearchOutlined,
    SoundFilled, StopFilled,
    TeamOutlined, UsergroupAddOutlined,
    UserOutlined
} from "@ant-design/icons";
import {CropIcon} from "../../Icons";
import api from "../../api";
import dayjs from "dayjs";
import HeaderHelpLink from "../HeaderHelpLink";
import {TranslationProvider, useTranslations} from "../../lang";
import LanguageSwitcher from "../LanguageSwitcher";

const {Header, Content, Footer, Sider} = Layout;


const AppLayout = () => {

    const navigate = useNavigate();
    const routingElement = useRoutes(routes)
    const [plan, setPlan] = useState({})
    const [company, setCompany] = useState({})
    const [user, setUser] = useState({name: "Mijn account"})
    const {translate} = useTranslations()

    let isExpired = false

    const goToPage = (e) => {
        navigate(e.key)
        if (isMobile) {
            handleOnCollapse(true ? "true" : "false")
        }
    }

    useEffect(() => {
        if (api.isLoggedIn()) {
            api.get_plan().then(setPlan)
            api.getMyCompany().then(setCompany)
            api.getMe().then(setUser)
        }
    }, []);

    useEffect(() => {
        if (!user.id) {
            return
        }
        console.log('Setting user id.', user.id)
        window.clarity("consent")
        window.clarity("identify", user.id.toString());
    }, [user]);

    let menuItems = [
        {
            key: 'gardens',
            label: translate('Tuinen'),
            onClick: goToPage,
            icon: <HomeFilled/>
        },
        {
            key: 'crops',
            label: translate('Gewassen'),
            onClick: goToPage,
            icon: <CropIcon/>
        },
        {
            key: 'profile',
            label: translate('Publiek profiel'),
            onClick: goToPage,
            icon: <IdcardFilled/>
        },
        {
            key: 'updates',
            label: translate('Updates'),
            onClick: goToPage,
            icon: <SoundFilled/>
        },
        {
            key: 'coaching',
            label: translate('Stel je vraag'),
            onClick: goToPage,
            icon: <QuestionCircleOutlined/>
        }
    ]

    if (plan.has_team) {
        menuItems.push({
            key: 'team',
            label: 'Mijn Team',
            onClick: goToPage,
            icon: <TeamOutlined/>
        })
    }

    if (company.plan_expires_at) {
        const dateToCheck = dayjs(company.plan_expires_at); // Example date
        const now = dayjs(); // Current date and time

        if (dateToCheck.isBefore(now)) {
            menuItems = []
            isExpired = true
        }
    }


    menuItems.push({
        key: 'account',
        label: user.name,
        onClick: goToPage,
        icon: <UserOutlined/>
    })

    if (api.isAdmin()) {
        menuItems.push({
            key: 'admin',
            label: 'Admin',
            onClick: goToPage,
            icon: <UsergroupAddOutlined/>
        })

        menuItems.push({
            key: 'seeds',
            label: translate('Zaden Zoeker'),
            onClick: goToPage,
            icon: <SearchOutlined/>
        })

        menuItems.push({
            key: 'admin/questions',
            label: 'User Questions',
            onClick: goToPage,
            icon: <QuestionCircleFilled/>
        })

    }

    if (localStorage.getItem('admin_token')) {
        menuItems.push({
            key: 'admin/impersonate/stop',
            label: 'Back to admin',
            onClick: goToPage,
            icon: <StopFilled/>
        })
    }


    menuItems.push({
        key: 'logout',
        label: translate('Uitloggen'),
        onClick: goToPage,
        icon: <LogoutOutlined/>
    })

    const menuOpenLocal = localStorage.getItem('menuOpen') == "true"

    const {useToken} = theme;

    const [collapsed, setCollapsed] = useState(isMobile ? true : menuOpenLocal);
    const {token} = useToken();
    const isLoggedIn = api.isLoggedIn()

    function handleOnCollapse(value) {
        localStorage.setItem('menuOpen', value)
        setCollapsed(value)
    }

    return (
        <Layout
            style={{
                minHeight: '100vh',
            }}
        >
            {(isLoggedIn && (
                <Sider defaultCollapsed={true}
                       className={"custom-slider"}
                       collapsedWidth={"40px"}
                       collapsible
                       collapsed={collapsed}
                       onCollapse={(value) => handleOnCollapse(value)}
                       trigger={null}
                >
                    <div style={{textAlign: 'right', paddingTop: '8px'}}>
                        <Button type={"link"} onClick={() => handleOnCollapse(!collapsed)}>
                            {collapsed ? (<MenuOutlined/>) : <CloseOutlined/>}
                        </Button>
                    </div>
                    <Menu defaultSelectedKeys={['1']} mode="inline" items={menuItems}/>
                </Sider>
            ))}
            <Layout>

                <Header
                    style={{
                        padding: 0,
                        background: 'white',
                    }}
                >
                    <Row>
                        <Col xs={8} md={16}>
                            <a href={"https://oogst.app"}>
                                <img style={{maxHeight: '40px', marginLeft: '28px'}} src={"/static/img/logo.png"}/>
                            </a>
                        </Col>
                        <Col xs={8} md={6}><HeaderHelpLink/></Col>
                        <Col xs={8} md={2}>
                            <LanguageSwitcher />
                        </Col>
                    </Row>
                </Header>

                <Content>
                    {isExpired && (
                        <div style={{padding: '20px', textAlign: 'center', background: 'white'}}>
                            <h1>{translate('Je abonnement is verlopen of niet actief!')}</h1>
                            <p>{translate('Je kunt nu geen gebruik meer maken van Oogst.app, doe eerst een betaling om verder te gaan.')}</p>
                            <Button onClick={() => {
                                api.retryPayment().then(({url}) => {
                                    document.location = url
                                })
                            }} type={"primary"}>{translate('Verleng je abonnement')}</Button>
                        </div>
                    )}
                    <div
                        style={{
                            padding: 24,
                            minHeight: 360,
                            background: token.colorBgContainer,
                        }}
                    >
                        {routingElement}
                    </div>
                </Content>
                <Footer
                    style={{
                        textAlign: 'center',
                    }}
                >
                    Oogst ©{new Date().getFullYear()}
                </Footer>
            </Layout>
        </Layout>
    );
};
export default AppLayout;