import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import 'antd/dist/reset.css';
import {ConfigProvider} from 'antd';

import './index.css';

import dayjs from "dayjs";
import 'dayjs/locale/nl';
import AppLayout from "./Components/AppLayout";
import {BrowserRouter as Router} from "react-router-dom";
import {TranslationProvider} from "./lang";


dayjs.locale("nl")

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <TranslationProvider>
            <ConfigProvider theme={{
                token: {
                    colorBgBase: '#F9F4E8',
                    borderRadiusLG: '10px',
                    colorPrimary: '#006e2f',
                    colorLink: '#006e2f',
                    colorLinkHover: '#005724',
                }
            }}>
                <Router>
                    <AppLayout/>
                </Router>
            </ConfigProvider>
        </TranslationProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
