import api from "../../api";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {
    Alert,
    Button,
    Checkbox,
    Col,
    Drawer,
    Form, Modal,
    Row,
    Select,
    Spin,
    Tabs,
    Tooltip
} from "antd";
import PlantingModal from "../../Components/PlantingModal";
import BedModal from "../../Components/BedModal";
import dayjs from "dayjs";
import "./style.css"
import {
    OrderedListOutlined,
    PlusOutlined,
    SettingFilled,
    WarningOutlined
} from "@ant-design/icons";
import {PLANTING_STATUS, PLANTING_STATUS_LABELS} from "../../constants";

import {bedDimensionsToHuman, filterPlanting} from "../../helpers";
import {isMobile} from "react-device-detect";
import LoomVideo from "../../Components/LoomVideo";
import {useTranslations} from "../../lang";
import BedOrder from "../../Components/BedOrder";

export default function PlannerV2() {

    const {translate} = useTranslations()

    const groupByPeriodLocal = localStorage.getItem('groupByPeriod')
    const hideHarvestedLocal = localStorage.getItem('hideHarvested')
    const compactModeLocal = localStorage.getItem('compactMode')
    const defaultDateRangeLocal = localStorage.getItem('defaultDateRange')
    const hideBedDimensionsLocal = localStorage.getItem('hideBedDimensions')
    const hideCropVarietiesLocal = localStorage.getItem('hideCropVarieties')

    const [defaultDateRange, setDefaultDateRange] = useState(defaultDateRangeLocal ?? "next_12_months")
    const [groupByPeriod, setGroupByPeriod] = useState(groupByPeriodLocal ?? "month")
    const [hideHarvested, setHideHarvested] = useState(hideHarvestedLocal ? hideHarvestedLocal === "true" : true)
    const [compactMode, setCompactMode] = useState(hideHarvestedLocal ? compactModeLocal === "true" : false)
    const [hideBedDimensions, setHideBedDimensions] = useState(hideBedDimensionsLocal ? hideBedDimensionsLocal === "true" : false)
    const [hideCropVarieties, setHideCropVarieties] = useState(hideBedDimensionsLocal ? hideCropVarietiesLocal === "true" : true)

    const navigate = useNavigate();

    let {id} = useParams();
    const [garden, setGarden] = useState(null)
    const [plantingModalOpen, setPlantingModalOpen] = useState(false)
    const [selectedBed, setSelectedBed] = useState(null)
    const [selectedPlanting, setSelectedPlanting] = useState(null)
    const [bedModalOpen, setBedModalOpen] = useState(false)
    const [allGardens, setAllGardens] = useState([])
    const [showDisplayDrawer, setShowDisplayDrawer] = useState(false)
    const [showSortingDrawer, setShowSortingDrawer] = useState(false)
    const [startDate, setStartDate] = useState(dayjs().startOf('month'))
    const [endDate, setEndDate] = useState(dayjs().endOf('year'))
    const [periods, setPeriods] = useState([])

    function openModal(bed) {
        setSelectedBed(bed)
        setBedModalOpen(true)
    }

    function loadGarden() {
        api.garden(id).then(setGarden)
        api.gardens().then(setAllGardens)
        setPreSelectedDateRange(defaultDateRange)
        if (groupByPeriod === "month" || groupByPeriod === "week") {
            setPeriods(generatePeriods(startDate, endDate, groupByPeriod))
        }
    }

    function showPlantingModal(bed) {
        setSelectedBed(bed)
        setPlantingModalOpen(true)
    }

    function onClose() {
        setPlantingModalOpen(false);
        setBedModalOpen(false);
        setSelectedPlanting(null);
        setSelectedBed(null);
        loadGarden();
    }

    function generatePeriods(start, end, groupBy) {
        let current = start;
        if (groupBy === "text" || groupBy === "none") {
            return []
        }
        const groups = [];

        // Helper function to determine next grouping point
        const getNextGroupStart = (currentDate, groupBy) => {
            if (groupBy === 'week') {
                return currentDate.startOf('week').add(1, 'week');
            } else if (groupBy === 'month') {
                return currentDate.startOf('month').add(1, 'month');
            } else if (groupBy === 'year') {
                return currentDate.startOf('year').add(1, 'year');
            } else {
                throw new Error("Invalid groupBy value. Use 'week' or 'month'.");
            }
        };

        while (current.isBefore(end)) {
            const groupStart = current.startOf(groupBy);
            groups.push(groupStart);
            current = getNextGroupStart(current, groupBy);
        }

        return groups;
    }


    useEffect(() => {
        loadGarden()
    }, []);


    useEffect(() => {
        loadGarden()
    }, [id]);


    useEffect(() => {
        setPeriods(generatePeriods(startDate, endDate, groupByPeriod))
    }, [startDate, endDate, groupByPeriod]);


    if (garden === null) {
        return (<Spin/>)
    }

    const periodToHuman = (period) => {
        if (groupByPeriod === "month") {
            return period.format("MMM")
        }
        if (groupByPeriod === "week") {
            return period.format("w")
        }
        if (groupByPeriod === "year") {
            return period.format("YYYY")
        }
        return period.toString()
    }

    function inDateRange(start, end, period) {
        if (groupByPeriod === "month") {
            return period.month() >= start.month() && period.month() <= end.month()
        }
        if (groupByPeriod === "week") {
            return period.week() >= start.week() && period.week() <= end.week()
        }
        if (groupByPeriod === "year") {
            return period.year() >= start.year() && period.year() <= end.year()
        }
        return period.unix() >= start.unix() && period.unix() <= end.unix()
    }

    function inBed(planting, period) {
        return inDateRange(dayjs(planting.transplant_at), dayjs(planting.harvest_at), period)
    }

    function inSeed(planting, period) {
        return inDateRange(dayjs(planting.sow_at), dayjs(planting.transplant_at), period)
    }

    function inHarvest(planting, period) {
        return inDateRange(dayjs(planting.harvest_at), dayjs(planting.harvest_until), period)
    }

    function isNotOnTrack(planting) {
        if (dayjs().unix() > dayjs(planting.harvest_at).unix() && planting.status !== PLANTING_STATUS.HARVESTED) {
            return translate("Oogsttijd overschreden")
        }

        if (dayjs().unix() > dayjs(planting.transplant_at).unix() && planting.status === PLANTING_STATUS.SOWN) {
            return translate("Uitplanttijd overschreden")
        }

        if (dayjs().unix() > dayjs(planting.sow_at).unix() && planting.status === PLANTING_STATUS.PLANNED) {
            return translate("Zaaitijd overschreden")
        }
        return false
    }

    function setHideBedDimensionsLocal(e) {
        const checked = e.target.checked
        localStorage.setItem('hideBedDimensions', checked)
        setHideBedDimensions(checked)
    }

    function setHideCropVarietiesLocal(e) {
        const checked = e.target.checked
        localStorage.setItem('hideCropVarieties', checked)
        setHideCropVarieties(checked)
    }

    function setHideHarvestedLocal(e) {
        const checked = e.target.checked
        localStorage.setItem('hideHarvested', checked)
        setHideHarvested(checked)
    }

    function setCompactModeLocal(e) {
        const checked = e.target.checked
        localStorage.setItem('compactMode', checked)
        setCompactMode(checked)
    }

    function setGroupBy(period) {
        localStorage.setItem('groupByPeriod', period)
        setGroupByPeriod(period)
        setPeriods(generatePeriods(startDate, endDate, period))
    }

    function setPreSelectedDateRange(selection) {
        localStorage.setItem('defaultDateRange', selection)
        setDefaultDateRange(selection)
        if (selection === "today_end_of_year") {
            setStartDate(dayjs().startOf(groupByPeriod))
            setEndDate(dayjs().endOf('year'))
        }
        if (selection === "start_of_year_today") {
            setStartDate(dayjs().startOf('year'))
            setEndDate(dayjs())
        }
        if (selection === "this_year") {
            setStartDate(dayjs().startOf('year'))
            setEndDate(dayjs().endOf('year'))
        }
        if (selection === "next_year") {
            setStartDate(dayjs().startOf('year').add(1, 'year'))
            setEndDate(dayjs().endOf('year').add(1, 'year'))
        }
        if (selection === "next_12_months") {
            setStartDate(dayjs().startOf(groupByPeriod))
            setEndDate(dayjs().add(11, 'months'))
        }
        if (selection === "next_3_months") {
            setStartDate(dayjs().startOf(groupByPeriod))
            setEndDate(dayjs().add(3, 'months'))
        }
    }

    function navigateToPlanting(bed, planting) {
        navigate(`/garden/${garden.id}/bed/${bed.id}/planting/${planting.id}`)
    }

    const activeTabKey = `garden_tab_${allGardens.findIndex(g => g.id === parseInt(id))}`;
    return (
        <div className={compactMode ? "planner-compact" : 'planner-large'}>
            <Tabs
                activeKey={activeTabKey}
                defaultActiveKey="garden_tab_1"
                type="card"
                size={"large"}
                style={{
                    marginBottom: 32,
                }}
                onTabClick={(key) => {
                    const id = key.replace('garden_tab_', '')
                    navigate(`/planner/${id}`)
                }}
                items={allGardens.map(g => ({
                    key: `garden_tab_${g.id}`,
                    label: g.name
                }))}
            />
            <Drawer open={showSortingDrawer} onClose={() => {
                setShowSortingDrawer(false)
                loadGarden()
            }}>
                <BedOrder beds={garden.beds} gardenId={garden.id}></BedOrder>
            </Drawer>
            <Drawer open={showDisplayDrawer} onClose={() => setShowDisplayDrawer(false)}>
                <Form.Item label={"Weergave kalender"} layout={"vertical"}>
                    <Select size={"small"}
                            defaultValue={groupByPeriod}
                            onChange={(e) => setGroupBy(e)}
                            options={[
                                {label: translate("Niet"), value: "none"},
                                {label: translate("Als tekst"), value: "text"},
                                {label: translate("Per week"), value: "week"},
                                {label: translate("Per maand"), value: "month"},
                                // {label: "Per jaar", value: "year"},
                            ]}
                    >
                    </Select>
                </Form.Item>
                <Form.Item
                    help={translate("Als je dit aanvinkt, zal alles wat je al als geoogst hebt gemarkeerd worden verborgen.")}>
                    <Checkbox checked={hideHarvested}
                              onChange={setHideHarvestedLocal}></Checkbox> {translate('Verberg geoogst')}
                </Form.Item>
                <Form.Item
                    help={translate("Deze optie zorgt ervoordat alles compacter wordt weergegeven, dit is vooral handig als je heel veel bedden in 1 tuin hebt! Deze optie werkt alleen op desktop, niet op mobiele apparaten")}>
                    <Checkbox disabled={isMobile} checked={compactMode}
                              onChange={setCompactModeLocal}></Checkbox> {translate('Compacte modus')}
                </Form.Item>
                <Form.Item
                    help={translate("Als je dit aanvinkt worden de maten van de bedden verborgen. Dit is makkelijk als als je bedden dezelfde maat hebben.")}>
                    <Checkbox checked={hideBedDimensions}
                              onChange={setHideBedDimensionsLocal}></Checkbox> {translate('Verberg afmetingen van bedden')}
                </Form.Item>
                <Form.Item
                    help={translate("Stel, je kweekt meerdere rassen van bijvoorbeeld tomaten. Dan is het handig om te zien om welk ras het gaat. Als je dit ook wilt zien in je planner moet je dit uitvinken")}>
                    <Checkbox checked={hideCropVarieties}
                              onChange={setHideCropVarietiesLocal}></Checkbox> {translate('Verberg rassen van gewassen')}
                </Form.Item>

                <h1 className={"mar-t-2"}>{translate('Legenda')}</h1>
                <div className={"period-in-seed pad-1 mar-b-1"}>
                    {translate('Het gewas wordt voorgezaaid, op een andere plek dan in het bed')}
                </div>
                <div className={"period-in-bed pad-1 mar-b-1"}>
                    {translate('Gewas staat in de grond / neemt ruimte in het bed')}
                </div>
                <div className={"period-in-harvest pad-1 mar-b-1"}>
                    {translate('Het gewas is te oogsten')}
                </div>
            </Drawer>
            <Row>
                <Col xs={16} md={16}>
                    <Form.Item>
                        <Select defaultValue={defaultDateRange}
                                style={{width: '90%'}}
                                onChange={(e) => setPreSelectedDateRange(e)}
                                options={[
                                    {label: translate("Vandaag tot einde jaar"), value: "today_end_of_year"},
                                    {label: translate("Begin dit jaar tot vandaag"), value: "start_of_year_today"},
                                    {label: translate("Dit jaar"), value: "this_year"},
                                    // {label: translate("Volgend jaar"), value: "next_year"},
                                    {label: translate("Komende 3 maanden"), value: "next_3_months"},
                                    {label: translate("Komende 12 maanden"), value: "next_12_months"}
                                ]}></Select>
                    </Form.Item>
                </Col>
                <Col xs={0} md={8} className={"text-right"}>
                    <Button onClick={() => setShowDisplayDrawer(!showDisplayDrawer)} className={"mar-r-1"}>
                        <SettingFilled/> {translate('Weergave instellingen')}
                    </Button>
                    <Button onClick={() => setShowSortingDrawer(!showDisplayDrawer)}>
                        <OrderedListOutlined/> {translate('Volgorde bedden')}
                    </Button>
                </Col>
                <Col xs={4} md={0}>
                    <Button onClick={() => setShowDisplayDrawer(!showDisplayDrawer)}>
                        <SettingFilled/>
                    </Button>
                </Col>
                <Col xs={4} md={0}>
                    <Button onClick={() => setShowSortingDrawer(!showDisplayDrawer)}>
                        <OrderedListOutlined/>
                    </Button>
                </Col>

            </Row>
            <div style={{height: '20px'}}></div>


            <PlantingModal
                open={plantingModalOpen}
                onClose={onClose}
                bed={selectedBed}
                planting={selectedPlanting}
                onPlantingAdded={(planting) => {
                    if (dayjs(planting.sow_at).unix() > endDate.unix()) {
                        Modal.info({
                            title: translate('Let op!'),
                            content: translate(
                                `De aanplant is automatisch gepland in de toekomst. Je ziet nu niks in de planner, want de zaaidatum valt buiten de geselecteerde periode. `
                                + `Je kunt de tijdlijn van de planner aanpassen door (bijvoorbeeld) de weergave op de komende 12 maanden te zetten.`
                            )
                        })
                    }
                    onClose()
                }}
            ></PlantingModal>

            <BedModal garden={garden} onCancel={onClose} open={bedModalOpen} bed={selectedBed}></BedModal>

            <h1 className={"garden-title"}>{garden.name} {startDate.year() === endDate.year() ? startDate.year() : `${startDate.year()} - ${endDate.year()}`}</h1>


            {garden.beds.map((bed) => {
                return (
                    <div className={"bed-wrapper"} key={`bed${bed.id}`}>
                        <Row>
                            <Col xs={12} md={compactMode ? 2 : 12}>
                                <p className={"bed-title"}>{bed.name} {hideBedDimensions ? null : `(${bedDimensionsToHuman(bed)})`}</p>
                            </Col>
                            <Col xs={12} md={compactMode ? 0 : 12} className={"bed-buttons"}>
                                {/* TODO MULTI BED */}
                                {/*{bed.mode === "multi" && (*/}
                                {/*    <Button onClick={() => navigate(`/garden/${garden.id}/bed/${bed.id}/multi`)} className={"mar-r-1"} size={compactMode ? 'small' : 'normal'}>*/}
                                {/*        <ArrowsAltOutlined/>*/}
                                {/*    </Button>*/}
                                {/*)}*/}
                                <Button className={"mar-r-1"} onClick={() => openModal(bed)}
                                        size={compactMode ? 'small' : 'normal'}>
                                    <SettingFilled/>
                                </Button>
                                <Button type="default"
                                        size={compactMode ? 'small' : 'normal'}
                                        onClick={() => showPlantingModal(bed)}>
                                    <PlusOutlined/>
                                </Button>
                            </Col>
                            <Col md={compactMode ? 20 : 24}>
                                {bed.plantings.filter((p) => filterPlanting(p, endDate, hideHarvested)).length === 0 && (
                                    <div className={"add-first-planting-button"}>
                                        <Button style={{margin: '0 8px'}} type="default" size={"small"}
                                                onClick={() => showPlantingModal(bed)}>
                                            <PlusOutlined/> {translate('Eerste gewas toevoegen aan bed')}
                                        </Button>
                                    </div>
                                )}
                                {bed.plantings.filter((p) => filterPlanting(p, endDate, hideHarvested)).map(planting => (
                                    <div key={`bed-${bed.id}-planting-${planting.id}`} className={"planting-wrapper"}>
                                        <Row>
                                            <Col xs={4} md={1} onClick={() => navigateToPlanting(bed, planting)}>
                                                <img alt={planting.crop.name} className={"planting-image"}
                                                     src={planting.crop.image}/>
                                            </Col>
                                            <Col xs={12} md={6} onClick={() => navigateToPlanting(bed, planting)}>
                                                <div className={"planting-name"}>
                                                    {planting.plants_per_row * planting.rows}x {planting.crop.name}{hideCropVarieties ? null : ` - ${planting.crop.variety}`}

                                                    {planting.note && compactMode && (<i className={"mar-l-1"}>{planting.note}</i>)}
                                                    {isNotOnTrack(planting) && (
                                                        <Tooltip title={isNotOnTrack(planting)}>
                                                            <WarningOutlined className={"mar-l-1"}/>
                                                        </Tooltip>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col xs={7} md={2}>
                                                <div className={"status-dropdown"}>
                                                    <Select size={"small"} defaultValue={planting.status}
                                                            onChange={(e) => {
                                                                api.updatePlanting(planting.id, {
                                                                    ...planting,
                                                                    status: e
                                                                }).then(() => {
                                                                    loadGarden()
                                                                })
                                                            }}
                                                            onClick={(e) => e.preventDefault()}>
                                                        {Object.keys(PLANTING_STATUS_LABELS).map(status => (
                                                            <Select.Option key={status}
                                                                           value={status}>{translate(PLANTING_STATUS_LABELS[status])}</Select.Option>
                                                        ))}
                                                    </Select>
                                                </div>
                                            </Col>
                                            {(groupByPeriod === "text") && (
                                                <Col md={12} xs={24} className={"text-center"}>
                                                    <Row onClick={() => navigateToPlanting(bed, planting)}>
                                                        <Col span={8} className={"planting-date"}>
                                                            <label>{translate('Zaaien')}</label>
                                                            <div>{dayjs(planting.sow_at).format("DD MMM YYYY")}</div>
                                                        </Col>
                                                        <Col span={8} className={"planting-date"}>
                                                            <label>{translate('Verplanten')}</label>
                                                            <div>{dayjs(planting.transplant_at).format("DD MMM YYYY")}</div>
                                                        </Col>
                                                        <Col span={8} className={"planting-date"}>
                                                            <label>{translate('Oogsten')}</label>
                                                            <div>{dayjs(planting.harvest_at).format("DD MMM YYYY")}</div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            )}
                                            {(periods.length > 0) && (
                                                <Col xs={24} md={15} style={{overflowX: 'auto'}}
                                                     onClick={() => navigateToPlanting(bed, planting)}>
                                                    <div className={"periods-wrapper"}>
                                                        {periods.map(period => (
                                                            <div className={
                                                                inBed(planting, period) ? "period-block period-in-bed" : inSeed(planting, period) ? "period-block period-in-seed" : inHarvest(planting, period) ? "period-block period-in-harvest" : "period-block"
                                                            } key={periodToHuman(period)}>
                                                                {periodToHuman(period)}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </Col>
                                            )}
                                            {planting.note && !compactMode && (<Col span={24}>
                                                <div className={"mar-t-1"}>
                                                    <i className={"mar-l-1"}>{planting.note}</i>
                                                </div>
                                            </Col>)}
                                        </Row>
                                    </div>
                                ))}
                            </Col>

                            <Col xs={0} md={compactMode ? 2 : 0} className={"bed-buttons"}>
                                <Button onClick={() => openModal(bed)} size={compactMode ? 'small' : 'normal'}>
                                    <SettingFilled/>
                                </Button>
                                <Button style={{marginLeft: '8px'}} type="default"
                                        size={compactMode ? 'small' : 'normal'}
                                        onClick={() => showPlantingModal(bed)}>
                                    <PlusOutlined/>
                                </Button>
                            </Col>
                        </Row>
                    </div>
                )
            })}

            {garden.beds.length === 0 && (

                <Alert
                    description={translate("Je moestuin heeft nog geen bedden, druk op de onderstaande knop om je eerste bed toe tevoegen!")}></Alert>
            )}

            <div style={{margin: '1rem 0'}}>
                <Button onClick={() => setBedModalOpen(true)} type={"primary"} size={"large"}>
                    {translate('Nieuw bed toevoegen')}
                </Button>
            </div>

            {garden.beds.length === 0 && (
                <>
                    <LoomVideo/>
                </>
            )}

        </div>
    )
}