import LogUpdateModal from "../LogUpdateModal";
import {Button, Col, Image, Row} from "antd";
import React, {useEffect, useState} from "react";
import api from "../../api";
import dayjs from "dayjs";
import UpdateCard from "../UpdateCard";
import {useTranslations} from "../../lang";

export default function PlantingUpdates({planting}) {

    const [openUpdateModal, setOpenUpdateModal] = useState(false);
    const [updates, setUpdates] = useState([])
    const {translate} = useTranslations()

    useEffect(() => {
        api.getPlantingUpdates(planting.id).then(setUpdates)
    }, [planting]);

    const onCancel = () => {
        setOpenUpdateModal(false);
        api.getPlantingUpdates(planting.id).then(setUpdates)
    }

    return (
        <div>
            <LogUpdateModal planting={planting} onCancel={onCancel} open={openUpdateModal}></LogUpdateModal>
            <Button type={"primary"} onClick={() => setOpenUpdateModal(true)}
                    className={"mar-b-1"}>{translate('Nieuwe update posten')}</Button>

            <Row gutter={12}>
                {updates.map(update => (
                    <Col key={update.id} md={6} xs={12}>
                        <UpdateCard update={update}/>
                    </Col>
                ))}
            </Row>
        </div>
    )
}