import React from 'react'
import {Button, Col, Row} from "antd";
import CropTiming from "../CropTiming";
import {useTranslations} from "../../lang";

export default function CropInfo({crop}) {
    function getDomainName(url) {
        const hostname = new URL(url).hostname;
        const domain = hostname.replace(/^www\./, ''); // Remove 'www.' if present
        return domain;
    }

    const {translate} = useTranslations()

    return (
        <>
            <Row gutter={[18, 18]}>
                <Col md={2} xs={6}>
                    {crop.image && (
                        <img src={crop.image} style={{maxWidth: '100%', borderRadius: '15px'}} alt={crop.name}/>
                    )}
                </Col>
                <Col md={18} xs={24}>
                    <Row>
                        <Col span={12}>{translate('Rijafstand')}</Col>
                        <Col>{crop.row_distance}cm</Col>
                    </Row>
                    <Row>
                        <Col span={12}>{translate('Plantafstand')}</Col>
                        <Col>{crop.plant_distance}cm</Col>
                    </Row>
                    <CropTiming crop={crop}/>
                </Col>
                <Col xs={24} md={4}>
                    {(crop.url) && (
                        <Button block href={crop.url} target={"_blank"}>
                            {translate('Bekijk op')} {getDomainName(crop.url)}
                        </Button>
                    )}
                </Col>
            </Row>
        </>
    )
}