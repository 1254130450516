import React from 'react'
import {Col, Row, Tag} from "antd";
import {useTranslations} from "../../lang";

export default function CropTiming({crop}) {

    const {translate} = useTranslations()

    const monthLables = [
        '-',
        translate('Jan'),
        translate('Feb'),
        translate('Mrt'),
        translate('Apr'),
        translate('Mei'),
        translate('Jun'),
        translate('Jul'),
        translate('Aug'),
        translate('Sep'),
        translate('Oct'),
        translate('Nov'),
        translate('Dec')
    ]

    const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

    const inPeriod = (month, min, max) => {
        if (month >= min && month <= max) {
            return true
        }
        return false
    }

    return (
        <>
            {(crop.sow_min !== 0 && crop.sow_max !== 0) && (
                <Row>
                    <Col xs={0} md={4} style={{lineHeight: '48px'}}>{translate('Voorzaaien')}</Col>
                    <Col xs={24} md={0}>{translate('Voorzaaien')}</Col>
                    <Col xs={24} md={20}>
                        <div className={"periods-wrapper"}>
                            {months.map(month => {
                                return (
                                    <div key={`seed${month}`}
                                         className={`period ${inPeriod(month, crop.sow_min, crop.sow_max) ? 'period-block period-in-seed' : 'period-block'}`}>
                                        {monthLables[month]}
                                    </div>
                                )
                            })}
                        </div>
                    </Col>
                </Row>
            )}
            {(crop.plant_min !== 0 && crop.plant_max !== 0) && (
                <Row>
                    <Col xs={0} md={4} style={{lineHeight: '48px'}}>{translate('Verplanten')}</Col>
                    <Col xs={24} md={0}>{translate('Verplanten')}</Col>
                    <Col xs={24} md={20}>
                        <div className={"periods-wrapper"}>
                            {months.map(month => {
                                return (
                                    <div key={`plant${month}`}
                                         className={`period ${inPeriod(month, crop.plant_min, crop.plant_max) ? 'period-block period-in-bed' : 'period-block'}`}>
                                        {monthLables[month]}
                                    </div>
                                )
                            })}
                        </div>
                    </Col>
                </Row>
            )}
            {(crop.harvest_min !== 0 && crop.harvest_max !== 0) && (
                <Row>
                    <Col xs={0} md={4} style={{lineHeight: '48px'}}>{translate('Oogsten')}</Col>
                    <Col xs={24} md={0}>{translate('Oogsten')}</Col>
                    <Col xs={24} md={20}>
                        <div className={"periods-wrapper"}>
                            {months.map(month => {
                                return (
                                    <div key={`harv${month}`}
                                         className={`period ${inPeriod(month, crop.harvest_min, crop.harvest_max) ? 'period-block period-in-harvest' : 'period-block'}`}>
                                        {monthLables[month]}
                                    </div>
                                )
                            })}
                        </div>
                    </Col>
                </Row>
            )}
        </>
    )
}