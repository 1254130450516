import React, {useEffect, useRef, useState} from 'react';
import {DndContext, closestCenter} from "@dnd-kit/core";
import {
    SortableContext,
    verticalListSortingStrategy,
    arrayMove,
    useSortable,
} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import {List} from "antd";
import api from "../../api";
import {useTranslations} from "../../lang";

export default function BedOrder({beds, gardenId}) {
    const {translate} = useTranslations();
    const SortableItem = ({id, item}) => {
        const {attributes, listeners, setNodeRef, transform, transition} =
            useSortable({id});

        const style = {
            transform: CSS.Transform.toString(transform),
            transition,
            cursor: "grab",
        };

        return (
            <List.Item ref={setNodeRef} style={style} {...attributes} {...listeners}>
                {item}
            </List.Item>
        );
    };

    const [items, setItems] = useState(beds);

    const onDragEnd = (event) => {
        const {active, over} = event;
        if (active.id !== over.id) {
            const oldIndex = items.findIndex((item) => item.id === active.id);
            const newIndex = items.findIndex((item) => item.id === over.id);
            const newItems = arrayMove(items, oldIndex, newIndex);
            setItems(newItems)
            api.saveBedOrder(gardenId, newItems.map((item) => item.id));
        }
    };

    return (
        <div>
            <h2>{translate('Volgorde van bedden aanpassen')}</h2>
            <p>{translate('Sleep de bedden op de gewenste volgorde! :)')}</p>
            <DndContext collisionDetection={closestCenter} onDragEnd={onDragEnd}>
                <SortableContext items={items.map((item) => item.id)} strategy={verticalListSortingStrategy}>
                    <List style={{touchAction: 'none'}}
                          bordered
                          dataSource={items}
                          renderItem={(item) => <SortableItem key={item.id} id={item.id} item={item.name}/>}
                    />
                </SortableContext>
            </DndContext>
        </div>
    );
};
