import react, {useEffect} from 'react';
import api from "../../api";
import {Button, Checkbox, Col, Form, Input, message, Modal, Row} from "antd";
import {useNavigate} from "react-router-dom";
import './style.css'
import dayjs from "dayjs";
import React from "react";
import {PLANTING_STATUS} from "../../constants";
import {useTranslations} from "../../lang";

export default function Gardens() {

    const [gardens, setGardens] = react.useState([])
    const [todos, setTodos] = react.useState({})
    const [newGardenForm] = Form.useForm()
    const [editGardenForm] = Form.useForm()
    const navigate = useNavigate()
    const [gardenSettingsModal, setGardenSettingsModal] = react.useState(false)
    const [editingGarden, setEditingGarden] = react.useState(null)
    const {translate} = useTranslations()

    function updateGarden() {
        api.updateGarden(editingGarden.id, editGardenForm.getFieldValue('name')).then(() => {
            setGardenSettingsModal(false)
            loadGardens()
            message.success('De instellingen van je tuin zijn bijgewerkt!')
        })
    }

    function openGardenSettings(garden) {
        setGardenSettingsModal(true)
        setEditingGarden(garden)
        editGardenForm.setFieldsValue({name: garden.name})
    }

    function loadGardens() {
        api.gardens().then(setGardens)
    }

    useEffect(() => {
        api.gardens().then(setGardens)
    }, []);

    useEffect(() => {
        for (const garden of gardens) {
            api.getGardenTodos(garden.id).then((t) => {
                todos[garden.id] = t;
                setTodos({...todos})
            })
        }
    }, [gardens]);

    function createGarden() {
        const name = newGardenForm.getFieldValue("name")
        api.createGarden(name).then((garden) => {
            navigate('/planner/' + garden.id)
        })
    }

    const desktopCols = 12;
    const mobileCols = 24;

    function getNextStatus(planting) {
        if (dayjs().unix() > dayjs(planting.harvest_at).unix() && planting.status === PLANTING_STATUS.TRANSPLANTED) {
            return PLANTING_STATUS.HARVESTED
        }

        if (dayjs().unix() > dayjs(planting.transplant_at).unix() && planting.status === PLANTING_STATUS.SOWN) {
            return PLANTING_STATUS.TRANSPLANTED
        }

        if (dayjs().unix() > dayjs(planting.sow_at).unix() && planting.status === PLANTING_STATUS.PLANNED) {
            return PLANTING_STATUS.SOWN
        }
    }

    function getTodoDescription(planting) {

        const nextStatus = getNextStatus(planting)

        if (nextStatus === PLANTING_STATUS.SOWN) {
            return translate("Zaaien")
        }

        if (nextStatus === PLANTING_STATUS.TRANSPLANTED) {
            return translate("Verplanten")
        }

        if (nextStatus === PLANTING_STATUS.HARVESTED) {
            return translate("Oogsten")
        }

        return false
    }

    function goToNextStatus(planting) {
        const newPlanting = {...planting, status: getNextStatus(planting)}
        api.updatePlanting(planting.id, newPlanting).then(() => {
            loadGardens()
        })
    }

    function deleteGarden(garden) {
        Modal.confirm(({
            title: translate(`Weet je zeker dat je de tuin wilt verwijderen?`),
            content: translate("Dit kan niet ongedaan worden gemaakt!"),
            onOk: () => api.deleteGarden(garden.id).then(() => {
                loadGardens()
                message.success(translate('Tuin verwijderd!'))
                setGardenSettingsModal(false)
                editGardenForm.resetFields()
            })
        }))
    }

    return (
        <div>
            <h1>Tuinen</h1>
            <Modal
                open={gardenSettingsModal}
                cancelText={translate("Annuleren")}
                okText={translate("Opslaan")}
                onOk={() => updateGarden()}
                onCancel={() => {
                    setGardenSettingsModal(false)
                    editGardenForm.resetFields()
                }}
                footer={(
                    <Row>
                        <Col span={12} className={"text-left"}>
                            <Button danger onClick={() => deleteGarden(editingGarden)}>{translate('Tuin verwijderen')}</Button>
                        </Col>
                        <Col span={12} className={"text-right"}>
                            <Button onClick={() => setGardenSettingsModal(false)}
                                    className={"mar-r-1"}>{translate('Annuleren')}</Button>
                            <Button type={"primary"} onClick={() => updateGarden()}>{translate('Opslaan')}</Button>
                        </Col>
                    </Row>
                )}
            >
                <Form form={editGardenForm} layout={"vertical"} labelCol={24} wrapperCol={24}>
                    <Form.Item label={translate("Naam")} name={"name"}>
                        <Input placeholder={translate("Naam van de tuin")}/>
                    </Form.Item>
                </Form>
            </Modal>
            <Row gutter={18}>
                {gardens.map(garden => (
                    <Col key={`garden` + garden.id} md={desktopCols} xs={mobileCols}>
                        <div className={"garden-box"}>
                            <Row>
                                <Col xs={24} md={18}><h2>{garden.name}</h2></Col>
                                <Col xs={24} md={6}>
                                    <Button onClick={() => openGardenSettings(garden)} size={"small"}>
                                        {translate('Tuin instellingen')}
                                    </Button>
                                    <br/><br/>
                                </Col>
                            </Row>

                            {todos[garden.id] && todos[garden.id].length > 0 && (
                                <div>
                                    <h3>{translate('Nu te doen')}</h3>
                                    {todos[garden.id].map(planting => (
                                        <div className={"todo-wrap"} key={`todo` + garden.id + `-` + planting.id}>
                                            <Checkbox onClick={() => goToNextStatus(planting)}></Checkbox>
                                            <span className={"todo-text"}>
                                                {planting.crop.name} - {getTodoDescription(planting)} in {planting.bed.name}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            )}
                            <div style={{paddingTop: '15px'}}></div>
                            <Button type={"primary"} onClick={() => navigate('/planner/' + garden.id)}>
                                {translate('Open planner')}
                            </Button>
                        </div>
                    </Col>
                ))}
                <Col md={desktopCols} xs={mobileCols}>
                    <div className={"garden-box"}>
                        <h2>{translate('Nieuwe tuin')}</h2>
                        <Form form={newGardenForm} layout={"vertical"} labelCol={24} wrapperCol={24}>
                            <Form.Item name={"name"}>
                                <Input placeholder={translate("Naam van de tuin")}/>
                            </Form.Item>
                            <Form.Item>
                                <Button onClick={createGarden}>{translate('Toevoegen')}</Button>
                            </Form.Item>
                        </Form>
                    </div>
                </Col>
            </Row>
        </div>
    )
}
