import {Button} from "antd";
import dayjs from "dayjs";
import api from "../../api";
import {ArrowRightOutlined} from "@ant-design/icons";
import React from "react";
import './style.css'
import {useTranslations} from "../../lang";

export default function PlanBox({isActive, plan, company}) {
    const {translate} = useTranslations()
    return (
        <div className={(isActive) ? "plan-box active-plan" : "plan-box"}>
            <h2>{plan.name}</h2>
            {plan.price > 0 && (
                <>
                    <h3>€ {plan.price} {translate('voor 1 jaar')}</h3>
                </>
            )}
            {plan.price === 0 && (
                <h3>{translate('Voor altijd gratis!')}</h3>
            )}
            {plan.price === -1 && (
                <h3>{translate('Vraag offerte aan')}</h3>
            )}
            <p>{translate('Inclusief 21% BTW')}</p>

            <ul className={"plan-features"}>
                <li>{translate('Onbeperkt aantal tuinen')}</li>
                <li> {translate('Onbeperkt aantal bedden per tuin')}</li>

                {(plan.crop_limit && plan.crop_limit > 0) ? (
                    <li>
                        <b>{translate('Maximaal')} {plan.crop_limit}</b> {translate('gewassen')}
                    </li>
                ) : (
                    <li>
                        <b>{translate('Onbeperkt aantal')}</b> {translate('gewassen')}
                    </li>
                )}
                <li>{translate('Rij en plantafstand calculator')}</li>
                <li>{translate('Zaai, verplant en oogsttijden calculator')}</li>
                {(plan.has_plukkers) && (
                    <li>{translate('Koppeling met')} Plukkers.com</li>
                )}
                {(plan.has_bolster) && (
                    <li>{translate('Koppeling met')} Bolster.nl</li>
                )}

                {(plan.has_team) && (
                    <li>{translate('Manage je teamleden en hun taken')}</li>
                )}
            </ul>

            {(isActive && company) && (
                <>
                    <Button size={"large"} disabled>{translate('Je huidige pakket')}</Button>
                    {(company.plan_expires_at && (
                        <p className={"mar-t-1"}>
                            {translate('Je account verloopt op')}:<br/>
                            {dayjs(company.plan_expires_at).format("dddd D MMMM YYYY")}
                        </p>
                    ))}
                    {(plan.price > 0 && (
                            <Button type={"primary"} onClick={() => {
                                api.upgradePlan(plan.id).then(({url}) => {
                                    document.location = url
                                })
                            }}>{translate('Met 1 jaar verlengen')}</Button>)
                    )}
                </>
            )}
            {(!isActive && plan.requires_contact) && (
                <Button size={"large"} type={"dashed"} target={"_blank"}
                        href={"https://koalendar.com/e/kletsen-met-stef/"}>{translate('Plan een gesprek')}</Button>
            )}
            {(!isActive && !plan.requires_contact && plan.price > 0) && (
                <>
                    <Button type={"primary"} size={"large"} href={`/pay/${plan.id}`}>
                        {translate('Selecteer pakket')} <ArrowRightOutlined/>
                    </Button>

                    <p className={"text-center mar-t-1"}>{translate('Niet goed? Geld terug!')}</p>
                    <p className={"text-center mar-t-1"}>
                        <b>
                            {translate('Dit is geen abonnement!')}<br/>
                            {translate('Geen automatische verlenging!')}
                        </b>
                    </p>
                </>
            )}

        </div>
    )
}