import React, {useEffect, useState} from 'react'
import api from "../../api";
import {Button, Col, Form, Modal, Row} from "antd";
import dayjs from "dayjs";
import {ArrowRightOutlined} from "@ant-design/icons";
import './style.css'
import PlanBox from "../../Components/PlanBox";
import {useTranslations} from "../../lang";


export default function Account() {

    const [plan, setPlan] = useState(false)
    const [company, setCompany] = useState(false)
    const [plans, setPlans] = useState(false)
    const {translate} = useTranslations()

    useEffect(() => {
        api.get_plan().then(setPlan)
        api.getAllPlans().then(setPlans)
        api.getMyCompany().then(setCompany)
    }, []);


    return (
        <div>
            <h1>{translate('Mijn account')}</h1>
            <p>{translate('Welkom bij je account. Hier kun je je accountgegevens inzien en je abonnement aanpassen.')}</p>
            {plans && (
                <Row gutter={[90, 24]}>
                    {plans.map(p => (
                        <Col md={8} xs={24} key={`plan${p.id}`}>
                            <PlanBox plan={p} isActive={p.id === plan.id} company={company}></PlanBox>
                        </Col>
                    ))}
                </Row>
            )}


            <Row justify={"center"}>
                <Col md={12} xs={24}>
                    <h1 className={"text-center mar-t-2"}>{translate('Waarom vraag ik hier geld voor?')}</h1>
                    <div className={"video-wrapper"}>
                        <iframe
                            src="https://www.loom.com/embed/b4101c8635a34ba787a38e5a72bb736a?sid=3d6b8ad2-c43e-4112-bd76-754c79f81747"
                            frameBorder="0" webkitallowfullscreen mozallowfullscreen allowFullScreen
                            className={"video-frame"}></iframe>
                    </div>
                </Col>
            </Row>

        </div>
    )
}