import {Button, Dropdown, Menu, Space} from "antd";
import { DownOutlined } from "@ant-design/icons";
import {useTranslations} from "../../lang";

const LanguageSwitcher = () => {
  const {language, switchLanguage} = useTranslations();

  const items = [
    { key: "nl", label: (<Button size={"small"} type={"link"} onClick={() => switchLanguage('nl')}>🇳🇱 NL</Button>)},
    { key: "en", label: (<Button size="small" type={"link"} onClick={() => switchLanguage('en')}>🇬🇧 EN</Button>)},
    // { key: "de", label: (<Button type={"link"} onClick={() => switchLanguage('de')}>🇩🇪 DE</Button>)},
    // { key: "fr", label: (<Button type={"link"} onClick={() => switchLanguage('fr')}>🇫🇷 FR</Button>)}
  ];

  if(!language) {
      return null
  }

  return (
      <Dropdown menu={{items}} trigger={["click"]}>
          <Space>
            {items.find((i) => i.key === language).label}
            <DownOutlined/>
          </Space>
      </Dropdown>
  );
};

export default LanguageSwitcher;
