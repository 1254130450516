import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Input, Modal, Row, Spin, message, InputNumber, Select, Alert} from "antd";
import api from "../../api";
import './style.css'
import CropTiming from "../../Components/CropTiming";
import {DeleteOutlined, EditOutlined, ExportOutlined} from "@ant-design/icons";
import SearchCropModal from "../../Components/SearchCropModal";
import {useNavigate} from "react-router-dom";
import {monthOptions, PLANTING_STATUS, PLANTING_STATUS_LABELS} from "../../constants";
import LoomVideo from "../../Components/LoomVideo";
import debounce from 'lodash/debounce';
import {useTranslations} from "../../lang";

const {confirm} = Modal;

export default function Crops() {
    const [messageApi, contextHolder] = message.useMessage();
    const [crops, setCrops] = useState([])
    const [loading, setLoading] = useState(true)
    const [addCropModalOpen, setAddCropModalOpen] = useState(false)
    const [editCropModalOpen, setEditCropModalOpen] = useState(false)
    const [editingCrop, setEditingCrop] = useState(null);
    const [editCropForm] = Form.useForm();
    const [filterCropForm] = Form.useForm();
    const [uploadedFile, setUploadedFile] = React.useState(null);
    const navigate = useNavigate();
    const query = new URLSearchParams(window.location.search).get('search')
    const [isFiltering, setIsFiltering] = useState(false)
    const {translate} = useTranslations()

    function handleUpload(event) {
        event.preventDefault();
        api.uploadToCdn(event.target.files[0]).then(setUploadedFile);
    }

    function loadCrops() {
        setLoading(true)
        const filters = filterCropForm.getFieldsValue()

        setIsFiltering(Object.keys(filters).length > 0)

        api.crops(filters).then(setCrops).finally(() => setLoading(false))

        if (query) {
            setAddCropModalOpen(true)
        }
    }

    useEffect(loadCrops, [])

    function handleCropClick(crop) {
        setUploadedFile(crop.image)
        editCropForm.setFieldsValue(crop)
        setEditingCrop(crop)
        setEditCropModalOpen(true)
    }

    function handleEditModalClose() {
        setEditCropModalOpen(false)
        setEditingCrop(null)
        editCropForm.resetFields()
        setUploadedFile(null)
    }

    function handleCropSave() {
        const values = editCropForm.getFieldsValue()
        if (editingCrop === null) {
            values.image = uploadedFile
            api.createCrop(values).then(() => {
                handleEditModalClose()

                loadCrops()
            }).catch(error => {
                if (error.response.data.error === "CropLimitExceededException") {
                    Modal.error({
                        title: translate( "Je hebt het maximum aantal gewassen bereikt"),
                        okText: translate("Ga naar account instellingen"),
                        onOk: () => navigate('/account'),
                        content: (
                            <div>
                                <p>{translate("Je hebt het maximum aantal gewassen bereikt voor je account.")}</p>
                                <p>{translate("Wil je meer gewassen toevoegen? Upgrade dan.")}</p>
                            </div>
                        )
                    })
                    return
                }
            })
        } else {
            values.image = uploadedFile
            api.updateCrop(editingCrop.id, values).then(() => {
                handleEditModalClose()
                message.success(translate('De gegevens van het gewas zijn aangepast!'))
            })
        }
    }

    function deleteCrop(crop) {

        confirm({
            title: translate('Weet je zeker dat je dit gewas wilt verwijderen?'),
            content: translate('Het gewas zal ook uit je planning verwijderd worden. Dit kan niet ongedaan worden gemaakt!'),
            onOk() {
                api.deleteCrop(crop.id).then((res) => {
                    if (res) {
                        messageApi.open({
                            type: 'success',
                            content: translate('Het gewas is verwijderd'),
                        });
                    } else {
                        messageApi.open({
                            type: 'error',
                            content: translate('Kan het gewas niet verwijderen, het is nog in gebruik. Verwijder het eerst uit je planner'),
                        });
                    }
                    loadCrops()
                })
            },
            onCancel() {
            },
        });


    }

    return (
        <>
            {contextHolder}
            <SearchCropModal
                addCropModalOpen={addCropModalOpen}
                onCancel={() => setAddCropModalOpen(false)}
                onCropFound={(crop) => {
                    setEditCropModalOpen(true)
                    editCropForm.setFieldsValue(crop)
                    setUploadedFile(crop.image)
                    setAddCropModalOpen(false)
                }}
                onAddManually={() => {
                    setEditCropModalOpen(true)
                    setAddCropModalOpen(false)
                }}
            />
            <Modal open={editCropModalOpen} onCancel={() => handleEditModalClose()} onOk={() => handleCropSave()}>
                <h1>{translate('Nieuw gewas toevoegen')}</h1>
                <Form form={editCropForm} layout="vertical">
                    <Row gutter={12}>
                        <Col span={12}>
                            <Form.Item name="name" label={translate("Gewas")}>
                                <Input placeholder={translate("Bijv. Rode Biet")}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="variety" label={translate("Ras")}>
                                <Input placeholder={translate("Bijv. Kogel 2")}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={12}>
                        <Col span={12}>
                            <Form.Item name="sow_min" label={translate("Zaaien vanaf")}>
                                <Select options={monthOptions}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="sow_max" label={translate("Zaaien tot")}>
                                <Select options={monthOptions}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={12}>
                        <Col span={12}>
                            <Form.Item name="plant_min" label={translate("Uitplanten vanaf")}>
                                <Select options={monthOptions}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="plant_max" label={translate("Uitplanten tot")}>
                                <Select options={monthOptions}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={12}>
                        <Col span={12}>
                            <Form.Item name="harvest_min" label={translate("Oogsten vanaf")}>
                                <Select options={monthOptions}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="harvest_max" label={translate("Oogsten tot")}>
                                <Select options={monthOptions}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={12}>
                        <Col span={12}>
                            <Form.Item name="row_distance" label={translate("Rij afstand")}>
                                <InputNumber addonAfter={"cm"}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="plant_distance" label={translate("Plant afstand")}>
                                <InputNumber addonAfter={"cm"}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={12}>
                        <Col span={12}>
                            <Form.Item name="url" label={translate("URL / link")}>
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={translate("Afbeelding")}>
                                <input type="file" onChange={handleUpload}/>
                            </Form.Item>
                            {uploadedFile && <img src={uploadedFile} style={{maxWidth: "100%", maxHeight: '100px'}}
                                                  alt={uploadedFile}/>}
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <Row gutter={[12, 12]} className={"mar-b-1"}>
                <Col md={12} xs={12}>
                    <h1>{translate("Gewassen")}</h1>
                </Col>
                <Col md={12} xs={12} style={{textAlign: 'right'}}>
                    <Button type="primary" size={"large"} onClick={() => setAddCropModalOpen(true)}>
                        {translate('Gewas toevoegen')}
                    </Button>
                </Col>
            </Row>


            <Form form={filterCropForm} layout={"vertical"}>
                <Row gutter={[12, 12]}>
                    <Col md={4} xs={12}>
                        <Form.Item name={"search"} label={translate("Naam en/of ras")}>
                            <Input placeholder={translate("Zoeken op naam en/of ras")} />
                        </Form.Item>
                    </Col>
                    <Col md={4} xs={12}>
                        <Form.Item name={"sow_in"} label={translate("Zaaiperiode")}>
                            <Select mode={"multiple"}
                                    placeholder={translate("Filter op zaaiperiode")}
                                    defaultValue={[]}
                                    options={monthOptions}
                                    allowClear={true}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={4} xs={12}>
                        <Form.Item name={"plant_in"} label={translate("Verplantperiode")}>
                            <Select mode={"multiple"}
                                    placeholder={translate("Filter op (ver)plantperiode")}
                                    defaultValue={[]}
                                    options={monthOptions}
                                    allowClear={true}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={4} xs={12}>
                        <Form.Item name={"harvest_in"} label={translate("Oogstperiode")}>
                            <Select mode={"multiple"}
                                    placeholder={translate("Filter op oogstperiode")}
                                    defaultValue={[]}
                                    options={monthOptions}
                                    allowClear={true}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={4} xs={12}>
                        <Form.Item label={translate("Sorteer")} name={"sort"}>
                            <Select
                                defaultValue={"id_desc"} options={[
                                {
                                    label: translate("Nieuwste eerst"),
                                    value: "id_desc"
                                },
                                {
                                    label: translate("Oudste eerst"),
                                    value: "id_asc"
                                },
                                {
                                    label: translate("Zaaiperiode"),
                                    value: "sow_min_asc"
                                },
                                {
                                    label: translate("Verplantperiode"),
                                    value: "plant_min_asc"
                                },
                                {
                                    label: translate("Oogstperiode"),
                                    value: "harvest_min_asc"
                                }
                            ]}></Select>
                        </Form.Item>
                    </Col>
                    <Col xs={6} md={2}>
                        <Form.Item label={<></>}>
                            <Button type={"primary"} onClick={loadCrops}>{translate("Zoeken")}</Button>
                        </Form.Item>
                    </Col>
                    <Col xs={6} md={2}>
                        <Form.Item label={<></>}>
                            <Button onClick={() => {
                                filterCropForm.resetFields()
                                loadCrops()
                            }}>{translate('Reset')}</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>


            {loading && (
                <Spin/>
            )}

            {(!loading && !isFiltering && crops.length === 0) && (
                <>
                    <Alert
                        description={translate("Je hebt nog geen gewassen in je account staan. Klop op de knop `Gewas toevoegen` om ze direct toe tevoegen! :)")}></Alert>
                    <LoomVideo/>
                </>
            )}
            {(!loading && isFiltering && crops.length === 0) && (
                <Alert
                    description={translate("Helaas, er zijn geen gewassen gevonden die voldoen aan de filters. Probeer het opnieuw met andere filters.")}></Alert>
            )}

            {crops.map((crop) => {
                return (
                    <Row gutter={[16, 16]} className={"crop-row"} key={`crop${crop.id}`}>

                        <Col xs={0} md={4}>
                            {crop.image && (<img alt={crop.name} src={crop.image}/>)}
                        </Col>
                        <Col xs={24} md={16}>
                            <h2>{crop.name} - {crop.variety}</h2>
                            <p>{translate('Plantafstand:')} {crop.plant_distance}x{crop.row_distance}cm</p>
                            <CropTiming crop={crop}/>

                            {crop.plantings && crop.plantings.length > 0 && (
                                <>

                                    {crop.plantings.filter((p) => p.bed).map((planting) => {
                                        return (
                                            <Button href={`/garden/${planting.bed.garden_id}/bed/${planting.bed.id}/planting/${planting.id}`} key={planting.id} size={"small"} className={"mar-r-1"}>
                                                {planting.rows * planting.plants_per_row}x {translate('in bed')} {planting.bed.name} - {translate(PLANTING_STATUS_LABELS[planting.status])}
                                            </Button>
                                        )
                                    })}

                                </>
                            )}
                        </Col>
                        <Col xs={24} md={0} className={"text-center"}>
                            {crop.image && (<img alt={crop.name} src={crop.image}/>)}
                        </Col>
                        <Col style={{textAlign: 'right'}} xs={24} md={4}>
                            <Button onClick={() => handleCropClick(crop)} type="primary" className={"mar-b-1 mar-l-1"}>
                                <EditOutlined/> {translate('Aanpassen')}
                            </Button>
                            <Button danger onClick={() => deleteCrop(crop)} className="mar-l-1 mar-b-1">
                                <DeleteOutlined/> {translate('Verwijderen')}
                            </Button>

                            {crop.url && (
                                <Button className="mar-l-1" target="_blank" href={crop.url}>
                                    <ExportOutlined/> {translate('Naar website')}
                                </Button>
                            )}
                        </Col>
                    </Row>
                )
            })}
        </>
    )
}