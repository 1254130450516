import React, {useEffect, useRef, useState} from 'react';
import './style.css';
import api from "../../api";
import {useParams} from "react-router-dom";
import {Button, Col, Row} from "antd";
import {bedDimensionsToHuman} from "../../helpers";
import PlantingPreview from "../../Components/PlantingPreview";
import {SettingFilled, ZoomInOutlined, ZoomOutOutlined} from "@ant-design/icons";

const GardenMap = () => {
    const [bedPositions, setBedPositions] = useState([]);
    const snapToGrid = 5;
    const containerRef = useRef(null);

    const localZoom = localStorage.getItem('zoom');
    const [zoom, setZoom] = useState(localZoom ? parseFloat(localZoom) : 1);


    const {id} = useParams();
    useEffect(() => {
        api.garden(id).then((garden) => {
            setBedPositions(garden.beds.map(((bed, i) => ({
                ...bed,
                left: bed.left ? bed.left : snapToGrid,
                top: bed.top ? bed.top : (i * bed.width + i * snapToGrid)
            }))));
        });
    }, []);

    const handlePointerDown = (index, e) => {
        e.preventDefault(); // Prevents default touch behavior like scrolling
        containerRef.current.style.touchAction = 'none'; // Disables touch scrolling on the container
        startDragging(index, e);
    };

    const startDragging = (index, e) => {
        e.preventDefault();
        document.body.style.overflow = 'hidden'; // Disable body scrolling

        const containerRect = containerRef.current.getBoundingClientRect();
        const startX = ((e.clientX || e.touches[0].clientX) - containerRect.left - bedPositions[index].left * zoom) / zoom;
        const startY = ((e.clientY || e.touches[0].clientY) - containerRect.top - bedPositions[index].top * zoom) / zoom;

        const onPointerMove = (e) => {
            e.preventDefault();
            const updatedBeds = [...bedPositions];
            let newLeft = ((e.clientX || e.touches[0].clientX) - containerRect.left) / zoom - startX;
            let newTop = ((e.clientY || e.touches[0].clientY) - containerRect.top) / zoom - startY;

            if (newLeft < 0) {
                newLeft = 0;
            }
            if (newTop < 0) {
                newTop = 0;
            }

            if (snapToGrid > 0) {
                newLeft = Math.round(newLeft / snapToGrid) * snapToGrid;
                newTop = Math.round(newTop / snapToGrid) * snapToGrid;
            }

            updatedBeds[index].left = newLeft;
            updatedBeds[index].top = newTop;
            setBedPositions(updatedBeds);
        };

        const onPointerUp = () => {
            containerRef.current.style.touchAction = ''; // Disables touch scrolling on the container
            document.removeEventListener('mousemove', onPointerMove);
            document.removeEventListener('mouseup', onPointerUp);
            document.removeEventListener('touchmove', onPointerMove);
            document.removeEventListener('touchend', onPointerUp);

            api.setBedPositions(id, bedPositions.map((bed) => ({
                left: bed.left,
                top: bed.top,
                id: bed.id
            })));

            document.body.style.overflow = 'hidden'; // Disable body scrolling
        };

        document.addEventListener('mousemove', onPointerMove);
        document.addEventListener('mouseup', onPointerUp);
        document.addEventListener('touchmove', onPointerMove);
        document.addEventListener('touchend', onPointerUp);
    };

    const getRotationAngle = (bed) => {
        return bed.rotation || 0;
    };

    function saveZoom(newZoom){
        localStorage.setItem('zoom', newZoom);
        setZoom(newZoom)
    }

    return (
        <div>
            <Row>
                <Col>
                    <Button onClick={() => saveZoom(zoom + 0.1)}><ZoomInOutlined/></Button>
                    <Button onClick={() => saveZoom(zoom - 0.1)}><ZoomOutOutlined/></Button>

                </Col>
            </Row>
            <div ref={containerRef} className="bed-container">
                {bedPositions.map((bed, index) => (
                    <div
                        key={bed.name}
                        className="bed"
                        style={{
                            height: `${bed.width * zoom}px`,
                            width: `${bed.length * zoom}px`,
                            top: `${bed.top * zoom}px`,
                            left: `${bed.left * zoom}px`,
                            transform: `rotate(${getRotationAngle(bed)}deg)`,
                        }}
                        onMouseDown={(e) => handlePointerDown(index, e)}
                        onTouchStart={(e) => handlePointerDown(index, e)}
                    >
                        <div className={"bed-controls"}>
                            {bed.name}
                            <Button style={{float:'right'}} size={"small"}><SettingFilled/></Button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default GardenMap;