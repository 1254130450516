import React, {useEffect, useState} from 'react'
import {Button, Col, Form, Input, Modal, Row, Spin} from "antd";
import {PlusCircleFilled} from "@ant-design/icons";
import api from "../../api";

import debounce from 'lodash/debounce';
import CropSearchResults from "../CropSearchResults";
import {useTranslations} from "../../lang";


export default function SearchCropModal({addCropModalOpen, onCancel, onCropFound, onAddManually}) {
    const {translate} = useTranslations()
    const [addCropForm] = Form.useForm();
    const [cropInfoLoading, setCropInfoLoading] = useState(false)
    const [searchResults, setSearchResults] = useState([])
    const [searching, setSearching] = useState(false)
    const [didSearch, setDidSearch] = useState(false)
    const query = new URLSearchParams(window.location.search).get('search')

    useEffect(() => {
        if(query) {
            addCropForm.getFieldValue('query') || addCropForm.setFieldsValue({query})
            handleSearch()
        }
    }, []);

    function handleSearch(e) {
        setSearchResults([])
        setSearching(true)
        setDidSearch(true)
        api.search(addCropForm.getFieldValue('query')).then(setSearchResults).finally(() => setSearching(false))
    }

    function getCropInfo(name, variety, url, supplier) {
        setCropInfoLoading(true)
        api.getCropInfo({name, variety, url, supplier}).then((response) => {
            onCropFound(response)
            closeSearchModal()
        })
    }

    function closeSearchModal() {
        setDidSearch(false)
        setCropInfoLoading(false)
        setSearching(false)
        setSearchResults([])
        addCropForm.resetFields()
        onCancel()
    }

    return (
        <Modal open={addCropModalOpen}
               title={translate('Nieuw gewas toevoegen')}
               onCancel={closeSearchModal}
               footer={null}>
            <Form form={addCropForm} layout="vertical">
                <Row>
                    <Col span={18}>
                        <Form.Item label={translate('Zoek naar gewassen')} name="query">
                            <Input disabled={cropInfoLoading} onChange={debounce(handleSearch, 300)}/>
                        </Form.Item>
                    </Col>
                    <Col span={6} className={"text-right"}>
                        <Form.Item label={" "}>
                            <Button type={"primary"} onClick={handleSearch} loading={searching} disabled={searching}>Zoeken</Button>
                        </Form.Item>
                    </Col>
                </Row>

                {!searching ? null : (<>

                    <div className={"text-center"}>
                        <Spin/>
                        <div>{translate('Een moment geduld, we zoeken nu naar zaden bij Plukkers en De Bolster...')}</div>
                    </div>
                </>)}
                {!cropInfoLoading ? (
                    <>
                        <CropSearchResults searchResults={searchResults} onSelect={(crop) => {
                            getCropInfo(crop.name, crop.variety, crop.url, crop.supplier)
                        }} label={(<PlusCircleFilled/>)}/>
                        {!searching && (
                            <Button className={"mar-t-1"} disabled={cropInfoLoading} onClick={onAddManually}>
                                {translate('Zoeken overslaan, zelf toevoegen')}
                            </Button>
                        )}
                    </>
                ) : (
                    <div className={"text-center"}>
                        <Spin/>
                        <div>{translate('Een moment geduld, we halen alle gegevens op van het geselecteerde gewas...')}</div>
                    </div>
                )}
            </Form>
        </Modal>
    )
}