import {useLocation} from "react-router-dom";
import {Button, Col, Modal, Row} from "antd";
import {PlaySquareOutlined} from "@ant-design/icons";
import "./style.css"
import {useState} from "react";
import LoomVideo from "../LoomVideo";
import {getVideoForPage} from "../../helpers";
import {useTranslations} from "../../lang";

function HeaderHelpLink() {

    const [modalOpen, setModalOpen] = useState(false);
    const {translate} = useTranslations();
    const location = useLocation();
    function showVideo(){
        setModalOpen(true)
    }


    const video = getVideoForPage(location);
    if(!video) {
        return null
    }

    return (
        <>
            <Modal open={modalOpen} width={"90%"} destroyOnClose={true} onCancel={() => setModalOpen(false)} footer={(
                <Button onClick={() => setModalOpen(false)}>{translate('Sluiten')}</Button>
            )}>
                {modalOpen && (
                    <LoomVideo/>
                )}
            </Modal>
            <Row>
                <Col xs={24} md={0}>
                    <div className={"header-help-link-wrap"}>
                        <Button onClick={() => showVideo()}>
                            <PlaySquareOutlined/> {translate('Help')}
                        </Button>
                    </div>
                </Col>
                <Col md={24} xs={0}>
                    <div className={"header-help-link-wrap"}>
                        <Button onClick={() => showVideo()}>
                            <PlaySquareOutlined/> {translate('Hoe werkt dit?')}
                        </Button>
                    </div>
                </Col>
            </Row>

        </>
    )
}

export default HeaderHelpLink